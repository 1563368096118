import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"
import Text from "../../components/Text"
import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import BreadcrumbsTreatmentsWrapper from "./breadcrumbsBlog.style"

const BreadcrumbsBlog = ({
  breadcrumbItem,
  sectionBreadcrumb,
  treatmentCategory,
  treatmentTitle,
  slug,
}) => {
  return (
    <BreadcrumbsTreatmentsWrapper>
      <Container width="1805px">
        <Box {...sectionBreadcrumb} className="box_wrapper">
          <Fade up delay={100}>
            <Link to="/">
              <Text content="Home" {...breadcrumbItem} />
            </Link>
            <Text content=">" {...breadcrumbItem} />
            <Link to="/blog/">
              <Text content="Blog" {...breadcrumbItem} />
            </Link>
            <Text content=">" {...breadcrumbItem} />
            <Text content={`${treatmentTitle}`} {...breadcrumbItem} />
          </Fade>
        </Box>
      </Container>
    </BreadcrumbsTreatmentsWrapper>
  )
}

// BreadcrumbsTreatments style props
BreadcrumbsBlog.propTypes = {
  breadcrumbItem: PropTypes.object,
  sectionBreadcrumb: PropTypes.object,
  treatmentCategory: PropTypes.string,
  slug: PropTypes.string,
  treatmentTitle: PropTypes.string,
}

BreadcrumbsBlog.defaultProps = {
  sectionBreadcrumb: {
    flexBox: true,
  },
  // breadcrumbItem default style
  breadcrumbItem: {
    as: "span",
    fontSize: "14px",
    fontWeight: "300",
    mr: "6px",
    color: "#767474",
  },
}

export default BreadcrumbsBlog
