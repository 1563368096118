import Fade from "react-reveal/Fade"
import Img from "gatsby-image"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const BlogPost = ({
  className,
  thumbUrl,
  title,
  category,
  author,
  date,
  link,
  slug,
}) => {
  // Add all classes to an array
  const addAllClasses = ["blog_post"]

  // className prop checking
  if (className) {
    addAllClasses.push(className)
  }

  const categoryColor =
    category === "aesthetics"
      ? " #DECCB4"
      : category === "health"
      ? "#A1D4D5"
      : "#FFB1AD"

  return (
    <Fade up delay={100}>
      <div className={addAllClasses.join(" ")}>
        <div className="blog-image-container">
          <Img fluid={thumbUrl} alt={title} className="blog-image" />
        </div>

        <div className="content">
          <span
            className="post_category"
            style={{ backgroundColor: categoryColor }}
          >
            {category}
          </span>
          {/* <div>
            <p className="post_details">
              {author} | {date}
            </p>
          </div> */}
          <h3 className="title">{title}</h3>
          <Link
            to={`/blog/${slug}/`}
            className="read_more"
            aria-label="read more about the article"
          >
            Read the full blog post &rarr;
          </Link>
        </div>
      </div>
    </Fade>
  )
}

BlogPost.propTypes = {
  className: PropTypes.string,
  thumbUrl: PropTypes.object,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  link: PropTypes.element,
  category: PropTypes.string,
  author: PropTypes.string,
  date: PropTypes.string,
  blogPostSlug: PropTypes.string,
}

export default BlogPost
