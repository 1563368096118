import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  width: 100%;
  padding: 120px 0 120px;
  @media only screen and (max-width: 1440px) {
    padding: 120px 0 120px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 90px 0 90px;
  }
  @media (max-width: 990px) {
    padding: 40px 0 56px;
  }
  @media (max-width: 767px) {
    padding: 32px 0 56px;
  }
  @media only screen and (max-width: 480px) {
    padding: 24px 0 0px;
  }

  .decorated {
    color: ${themeGet("colors.textColor", "#333333")};
    font-size: 32px;
    letter-spacing: 1.28px;
    font-weight: 300;
    overflow: hidden;
    text-align: center;
  }
  .decorated > span {
    position: relative;
    display: inline-block;
  }
  .decorated > span:before,
  .decorated > span:after {
    content: "";
    position: absolute;
    top: 50%;
    border-bottom: 1px solid #eaeaea;
    width: 592px; /* half of limiter */
    margin: 0 20px;
  }
  .decorated > span:before {
    right: 100%;
  }
  .decorated > span:after {
    left: 100%;
  }
`

export const PostsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  margin-top: 90px;
  @media only screen and (max-width: 1440px) {
    font-size: 36px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 30px;
    margin-bottom: 0px;
    margin-top: 56px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 28px;
  }
  @media only screen and (max-width: 480px) {
    margin-bottom: 56px;
  }
  &:last-child {
    @media only screen and (max-width: 480px) {
      margin-bottom: 0px;
    }
  }

  .blog_post {
    width: calc(100% / 3 - 40px);
    box-shadow: 0 0 100px rgba(175, 182, 194, 0.3);
    background-color: ${themeGet("colors.white", "#ffffff")};
    margin-bottom: 90px;
    margin-top: 40px;
    text-align: left;

    @media only screen and (max-width: 1360px) {
      width: calc(100% / 3 - 30px);
      margin: 0 0 90px;
    }
    @media only screen and (max-width: 991px) {
      width: 100%;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 56px;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }

  .read_more {
    color: ${themeGet("colors.secondaryTextColor", "#297E7F")};
    font-size: 16px;
    font-weight: 300;
  }

  .thumbnail {
    img {
      height: 80px;
      width: auto;
      margin-bottom: 38px;
      @media only screen and (max-width: 1440px) {
        height: 90px;
        margin-bottom: 35px;
      }
      @media only screen and (max-width: 1360px) {
        height: 80px;
        margin-bottom: 27px;
      }
      @media only screen and (max-width: 991px) {
        height: 67px;
        margin-bottom: 20px;
      }
    }
  }

  .content {
    padding: 24px 16px 24px 16px;
    h3 {
      color: ${themeGet("colors.textColor", "#333333")};
      margin: 8px 0 44px;
      font-size: 22px;
      line-height: 30px;
      font-weight: 300;
      letter-spacing: 0.88px;
      @media only screen and (max-width: 1440px) {
        font-size: 22px;
      }
      @media only screen and (max-width: 1360px) {
        font-size: 22px;
        line-height: 30px;
      }
      @media only screen and (max-width: 991px) {
        font-size: 22px;
        line-height: 30px;
      }
    }

    p {
      margin: 0;
      font-size: 14px;
      line-height: 28px;
      color: #767474;
      margin-top: 16px;
    }

    .post_category {
      font-size: 12px;
      line-height: 28px;
      color: ${themeGet("colors.textColor", "#333333")};
      padding: 4px 16px;
      border-radius: 16px;
      text-transform: capitalize;
      @media (max-width: 480px) {
        font-size: 14px;
      }
    }
  }
`

export default SectionWrapper
