import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const BlogPostContentWrapper = styled.section`
  overflow: hidden;
  margin: 0 auto;
  text-align: center;

  .intro {
    color: ${themeGet("colors.textColor", "#333333")};
    line-height: 28px;
    max-width: 826px;
    font-size: 16px;
    margin: 40px auto 80px auto;
    text-align: center;
    @media (max-width: 991px) {
      margin: 24px auto 40px auto;
    }
  }
`

export const ContentSection = styled.div`
  margin: 80px auto 60px auto;
  text-align: left;
  max-width: 858px;
  color: ${themeGet("colors.textColor", "#333333")};
  @media only screen and (max-width: 991px) {
    margin: 40px 0 40px 0;
  }

  h1 {
    font-size: 48px;
    letter-spacing: 0.8px;
    line-height: 55px;
    font-weight: 300;
    margin: 8px 0;
  }
  h2 {
    font-size: 40px;
    letter-spacing: 0.8px;
    line-height: 55px;
    font-weight: 300;
    margin: 8px 0;
  }
  h3 {
    font-size: 32px;
    letter-spacing: 0.64px;
    line-height: 55px;
    font-weight: 300;
    margin: 8px 0;
  }
  h4 {
    font-size: 24px;
    letter-spacing: 0.48px;
    line-height: 55px;
    font-weight: 300;
    margin: 8px 0;
  }
  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 40px;
  }

  li p {
    margin-bottom: 0px;
  }

  img {
    max-width: 100%;
    margin-bottom: 24px;
  }

  li {
    list-style-type: disc;
    margin-left: 17px;
  }
`

export const SocialShareWrapper = styled.div`
  margin: 0 auto 0 auto;
  max-width: 858px;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;

  .social_share_content {
    padding: 24px 0 24px 0;
    display: flex;

    p {
      margin: 0 8px 0 0;
      font-size: 12px;
      color: #767474;
      @media (max-width: 480px) {
        font-size: 14px;
      }
    }
  }

  .icon_wrapper {
    margin-right: 14px;

    svg {
      width: 24px;
      height: 24px;
      color: #767474;
    }
  }
`

export const VideoContainer = styled.div`
  margin-top: 120px;

  @media only screen and (max-width: 991px) {
    margin-top: 56px;
  }
`

export default BlogPostContentWrapper
