import React from "react"
import Fade from "react-reveal/Fade"

import BlogPost from "../../components/BlogPost"
import Container from "../../components/UI/Container"
import SectionWrapper, { PostsWrapper } from "./similarBlogPosts.style"

const SimilarBlogPosts = ({ filteredSimilarBlogPosts }) => {
  return (
    <SectionWrapper>
      <Container width="1340px">
        <Fade up delay={100}>
          <h1 className="decorated">
            <span>Similar posts</span>
          </h1>
        </Fade>
        <PostsWrapper>
          {filteredSimilarBlogPosts.map(({ node }, index) => {
            return (
              <>
                <BlogPost
                  key={index}
                  slug={node.blogPostSlug}
                  thumbUrl={node.blogPostMainImage.fluid}
                  category={node.blogPostCategory}
                  author="Skikha Rishi"
                  date={node.createdAt}
                  title={node.blogPostTitle}
                />
              </>
            )
          })}
        </PostsWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default SimilarBlogPosts
