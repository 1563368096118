import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  max-width: 858px;
  margin: 120px auto 120px auto;
  @media only screen and (max-width: 991px) {
    margin: 56px auto 56px auto;
  }
  .container_wrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`

export const DetailsInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 120px;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    padding: 24px 24px;
    text-align: center;
    margin-bottom: 56px;
  }
`

export const Content = styled.div`
  h3 {
    color: ${themeGet("colors.textColor", "#333333")};
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 1.28px;
    margin-bottom: 8px;
    @media only screen and (max-width: 600px) {
      font-size: 24px;
      line-height: 35px;
    }
  }
  p {
    color: ${themeGet("colors.textColor", "#333333")};
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0px;
  }

  .about_header {
    font-size: 16px;
    font-weight: 300;
    font-family: "bariol-regular";
    margin-bottom: 8px;
    @media only screen and (max-width: 991px) {
      margin: 0 auto 8px auto;
    }
  }
`

export const ImageWrapper = styled.div`
  img {
    max-width: 163px;
    margin-right: 40px;
    @media only screen and (max-width: 991px) {
      margin-right: 0px;
      margin-bottom: 32px;
    }
  }
  > div {
    display: flex;
    @media only screen and (max-width: 480px) {
      flex-direction: column;
    }
  }
  @media only screen and (max-width: 480px) {
    align-items: center;
  }
`

export const MiniHeader = styled.div`
  display: flex;
  .seperator {
    letter-spacing: -0.14rem;
    color: ${themeGet("colors.secondaryColor", "#297E7F")};
    margin-top: 3px;
  }
`

export const PaginationWrapper = styled.div`
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  padding: 32px 0;
  @media only screen and (max-width: 991px) {
    margin: 0 20px;
  }

  .pagination_content {
    display: flex;
    justify-content: space-between;

    p {
      margin: 0 8px 0 0;
      font-size: 12px;
      color: #767474;
    }
  }
`

export const PaginationLinks = styled.div`
  display: flex;
  color: ${themeGet("colors.secondaryColor", "#297E7F")};
  font-size: 14px;
  p {
    margin: 0 24px;
  }
  img {
    width: 80px;
    @media only screen and (max-width: 480px) {
      display: none;
    }
  }
  .back_arrow {
    margin-right: 19px;
  }
  .next_arrow {
    margin-left: 19px;
  }
`
export const BackLink = styled.div`
  display: flex;
  justify-content: center;
  color: ${themeGet("colors.secondaryColor", "#297E7F")};
  margin-top: 40px;
  font-size: 14px;
  p {
    margin: 0 24px;
  }
  img {
    width: 80px;
  }
  .back_arrow {
    margin-right: 19px;
  }
`

export default SectionWrapper
