import {
  Player,
  VideoContainer,
  VideoItem,
  VideoList,
} from "./videosPlayer.style"
import React, { useState } from "react"

import Video from "../../common/assets/videos/event.mp4"

const VideoPlayer = () => {
  const videos = [
    {
      title: "Event",
      src: Video,
    },

    // ... add more videos as needed
  ]

  const [currentVideo, setCurrentVideo] = useState(videos[0].src)

  return (
    <VideoContainer>
      <Player controls autoPlay src={currentVideo} />
    </VideoContainer>
  )
}

export default VideoPlayer
