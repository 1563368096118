import BlogPostContent from "../containers/BlogPostContent"
import BlogPostFooter from "../containers/BlogPostFooter"
import BreadcrumbsBlog from "../containers/BreadcrumbsBlog"
import ChangeYourLife from "../containers/ChangeYourLife"
import { ContentWrapper } from "../containers/elegant.style"
import Layout from "../containers/Layout"
import PageTitle from "../containers/PageTitle"
import React from "react"
import SEO from "../components/SEO"
import SimilarBlogPosts from "../containers/SimilarBlogPosts"
import _ from "lodash"
import { graphql } from "gatsby"

const BlogPostPageTemplate = ({
  location,
  pageContext,
  data: { contentfulBlogPost, allContentfulBlogPost },
}) => {
  const prev = pageContext.prev
    ? {
        url: `/blog/${pageContext.prev.blogPostSlug}/`,
      }
    : null
  const next = pageContext.next
    ? {
        url: `/blog/${pageContext.next.blogPostSlug}/`,
      }
    : null
  const {
    blogPostCategory,
    blogPostContent,
    blogPostIntroText,
    blogPostMainImage,
    blogPostSlug,
    blogPostTitle,
    blogPostSeoDetails,
  } = contentfulBlogPost

  var filteredSimilarBlogPosts = _.filter(
    allContentfulBlogPost.edges,
    function (item) {
      return item.node.blogPostSlug !== blogPostSlug
    }
  )

  const url = location.href ? location.href : ""

  return (
    <>
      <SEO
        title={blogPostSeoDetails.metaTitle}
        shortDesc={blogPostSeoDetails.metaShortDescription}
        description={blogPostSeoDetails.metaDescription}
        image={`https:${blogPostMainImage.fluid.src}`}
        url={url}
      />
      <Layout>
        <ContentWrapper>
          <BreadcrumbsBlog treatmentTitle={blogPostTitle} slug={blogPostSlug} />
          <PageTitle
            title={blogPostTitle}
            subtitle={blogPostCategory}
            addCategory
          />
          <BlogPostContent
            location={location}
            blogPostSlug={blogPostSlug}
            blogPostContent={blogPostContent}
            blogPostIntroText={blogPostIntroText}
            blogPostMainImage={blogPostMainImage}
          />
          <BlogPostFooter prev={prev} next={next} />
          <ChangeYourLife whiteBG />
          <SimilarBlogPosts
            filteredSimilarBlogPosts={filteredSimilarBlogPosts.slice(0, 3)}
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default BlogPostPageTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $category: String!) {
    contentfulBlogPost(blogPostSlug: { eq: $slug }) {
      blogPostCategory
      blogPostSeoDetails {
        metaShortDescription
        metaDescription
        metaTitle
      }
      blogPostContent {
        json
      }
      blogPostIntroText {
        blogPostIntroText
      }
      blogPostMainImage {
        fluid(quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      blogPostSlug
      blogPostTitle
    }
    allContentfulBlogPost(
      limit: 4
      sort: { fields: updatedAt }
      filter: { blogPostCategory: { eq: $category } }
    ) {
      edges {
        node {
          blogPostCategory
          blogPostSlug
          blogPostTitle
          blogPostMainImage {
            fluid(quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          createdAt(formatString: "MMMM DD, y")
        }
      }
    }
  }
`
