import React from "react"
import Fade from "react-reveal/Fade"
import { Link } from "gatsby"

import Author from "../../common/assets/image/elegant/author.png"
import BackArrow from "../../common/assets/image/elegant/back-arrow.svg"
import NextArrow from "../../common/assets/image/elegant/next-arrow.svg"
import Box from "../../components/Box"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import Container from "../../components/UI/Container"
import SectionWrapper, {
  Content,
  DetailsInner,
  ImageWrapper,
  MiniHeader,
  PaginationWrapper,
  PaginationLinks,
  BackLink,
} from "./blogPostFooter.style.js"

const BlogPostFooter = ({ prev, next }) => {
  return (
    <SectionWrapper>
      <Container width="858px" className="container_wrapper">
        <Fade up delay={100}>
          <DetailsInner>
            <ImageWrapper>
              <Fade up delay={100}>
                <img src={Author} alt="Shikha Banner" />
              </Fade>
            </ImageWrapper>
            <Content>
              <Fade up delay={200}>
                <MiniHeader>
                  <Text content="About the author" className="about_header" />
                </MiniHeader>
              </Fade>
              <Fade up delay={400}>
                <Heading as="h3" content="Shikha Rishi" />
                <Text content="Shikha is a highly experienced aesthetics practitioner, pharmacist and medical professional. After training for a number of years at the world-renowned Harley Street, London and SkinViva, Manchester, Shikha is passionate about delivering top-quality results in every treatment." />
              </Fade>
            </Content>
          </DetailsInner>
        </Fade>
        <Fade up delay={100}>
          <PaginationWrapper>
            <Box className="pagination_content">
              {prev === null ? (
                <p>No previous posts</p>
              ) : (
                <PaginationLinks>
                  <Link to={prev === null ? "" : prev.url}>
                    <img
                      src={BackArrow}
                      alt="back button"
                      className="back_arrow"
                    />
                    <span>Previous Post</span>
                  </Link>
                </PaginationLinks>
              )}
              {next === null ? (
                <p>No next posts</p>
              ) : (
                <PaginationLinks>
                  <Link to={next.url}>
                    <span>Next Post</span>
                    <img
                      src={NextArrow}
                      alt="next button"
                      className="next_arrow"
                    />
                  </Link>
                </PaginationLinks>
              )}
            </Box>
          </PaginationWrapper>
          <BackLink>
            <Link to="/blog/">
              <img src={BackArrow} alt="back button" className="back_arrow" />
              <span>Back to the Blog</span>
            </Link>
          </BackLink>
        </Fade>
      </Container>
    </SectionWrapper>
  )
}

export default BlogPostFooter
