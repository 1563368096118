import BlogPostContentWrapper, {
  ContentSection,
  SocialShareWrapper,
  VideoContainer,
} from "./blogPostContent.style"
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share"

import { BLOCKS } from "@contentful/rich-text-types"
import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import Fade from "react-reveal/Fade"
import { Icon } from "react-icons-kit"
import Image from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import Text from "../../components/Text"
import VideoPlayerAlt from "../VideoPlayerAlt"
/* eslint-disable react/display-name */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { envelopeO } from "react-icons-kit/fa/envelopeO"
import { facebook } from "react-icons-kit/fa/facebook"
import { twitter } from "react-icons-kit/fa/twitter"

// import { instagram } from "react-icons-kit/fa/instagram"
const BlogPostContent = ({
  imageWrapper,
  blogPostIntroText,
  blogPostMainImage,
  blogPostContent,
  blogPostSlug,
  location,
}) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img src={node.data.target.fields.file["en-US"].url} alt="blogpost" />
      ),
    },
  }
  return (
    <BlogPostContentWrapper>
      <Container width="1805px">
        <Text className="intro" content={blogPostIntroText.blogPostIntroText} />
        <Box {...imageWrapper}>
          <Image
            fluid={
              (blogPostMainImage !== null) | undefined
                ? blogPostMainImage.fluid
                : {}
            }
            alt="about image"
          />
        </Box>
        {location?.pathname ===
          "/blog/elegant-clinics-september-skin-soiree/" && (
          <Fade up delay={300}>
            <VideoContainer>
              <VideoPlayerAlt />
            </VideoContainer>
          </Fade>
        )}
        <ContentSection>
          {documentToReactComponents(blogPostContent.json, options)}
        </ContentSection>
        <SocialShareWrapper>
          <Box className="social_share_content">
            <Text content="Share:" />
            <FacebookShareButton
              className="icon_wrapper"
              url={`https://zealous-brown-0d5e4b.netlify.app/blog/${blogPostSlug}`}
            >
              <Icon icon={facebook} />
            </FacebookShareButton>
            {/* <InstapaperShareButton
                className="icon_wrapper"
                url={`https://zealous-brown-0d5e4b.netlify.app/blog/${blogPostSlug}`}
              >
                <Icon icon={instagram} />
              </InstapaperShareButton> */}
            <TwitterShareButton
              className="icon_wrapper"
              url={`https://zealous-brown-0d5e4b.netlify.app/blog/${blogPostSlug}`}
            >
              <Icon icon={twitter} />
            </TwitterShareButton>
            <EmailShareButton
              className="icon_wrapper"
              url={`https://zealous-brown-0d5e4b.netlify.app/blog/${blogPostSlug}`}
            >
              <Icon icon={envelopeO} />
            </EmailShareButton>
          </Box>
        </SocialShareWrapper>
      </Container>
    </BlogPostContentWrapper>
  )
}

// MainImage style props
BlogPostContent.propTypes = {
  imageWrapper: PropTypes.object,
  blogPostIntroText: PropTypes.object,
  blogPostMainImage: PropTypes.object,
  blogPostContent: PropTypes.object,
  blogPostSlug: PropTypes.object,
}

// MainImage default style
BlogPostContent.defaultProps = {
  imageWrapper: {
    display: " block",
    width: ["100%", "100%", "100%", "70%", "60%"],
    margin: "0 auto",
  },
}

export default BlogPostContent
